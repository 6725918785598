.loginpage {
  overflow: hidden;
  padding-left: 10%;
  margin-top: 20vh;
  .loginform {
    margin-top: 10vh;
    max-width: 300px;
    padding-left: 10%;
  }
  .advertise {
    padding-left: 10%;
  }
}
